export const geocode = async (address: string): Promise<{ lat: number; lon: number }> => {
    const geoRes = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(address)}`
    );
    const geoData = await geoRes.json();

    // console.log(geoData); // da nam to m.in. lat i lon

    const lat = Number(geoData[0].lat);
    // const lat = parseFloat(geoData[0].lat);
    const lon = Number(geoData[0].lon);
    // const lon = parseFloat(geoData[0].lon);

    console.log(lat, lon);

    return { lat, lon }
}